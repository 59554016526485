/**
 * Glossary of FUBI terms.
 */
import Icon from '@/icons/Icon.vue';

import GlossaryCard from '@/components/GlossaryCard.vue';

export default {
  name: 'Glossary',
  components: {
    Icon,
    GlossaryCard,
  },
  data: () => ({
    category: null,
  }),
  computed: {
    categories() {
      return Object.keys(this.$t('glossary'));
    },
    cardsSorted() {
      const cols = 2;
      const glossary = this.$t('glossary');
      const cards = [];

      Object.keys(glossary).forEach(c => {
        if (!this.category || this.category === c) {
          Object.keys(glossary[c]).forEach(g => {
            cards.push({ category: c, genre: g, ...glossary[c][g] });
          });
        }
      });

      const sortedCards = [];
      cards.forEach((card, i) => {
        if (!(i % cols)) {
          sortedCards.push([card]);
        } else {
          sortedCards[Math.floor(i / cols)].push(card);
        }
      });
      return sortedCards;
    },
  },
  methods: {
    clearFilters() {
      this.category = null;
    },
  },
};
